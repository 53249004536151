import React from 'react'
import { Backdrop, Button, Fade, IconButton, InputAdornment, Modal, Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';
import { Delete, Edit, Image, Title } from '@material-ui/icons';
import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { APIs, baseUrl } from 'variables/API';
import CustomInput from 'components/CustomInput/CustomInput';

const useStyles = makeStyles(styles);

function Portfolio() {
    const classes = useStyles();
    const [portfolios, setPortfolios] = useState([]);
    const [open, setOpen] = useState(false);
    const [editopen, setEditOpen] = useState(false);
    const [selectedPortfolio, selectPortfolio] = useState({});
    const [image, setImage] = useState({});
    const [title, setTitle] = useState("");
    useEffect(() => {
        fetchPortfolios();
    }, [])
    const fetchPortfolios = () => {
        axios.get(APIs.portfolios + '?is_delete=no').then(res => {
            console.log(res.data);
            setPortfolios(res.data.data.docs)
        })
    }

    const deletePortfolio = () => {
        const portfolio_id = selectedPortfolio._id;
        axios.delete(APIs.portfolios + "/delete/" + portfolio_id).then(res => {
            // if (res.data.status === 'success') {
            setOpen(false);
            fetchPortfolios();
            selectPortfolio({});
            // }
        }).catch(err => {
            if (err) {
                console.log(err)
            }
        })
    }

    const portfolioAddUpdate = (e) => {
        e.preventDefault();
        const portfolio_id = selectedPortfolio._id;
        if (portfolio_id) {
            const fd = new FormData();
            fd.append('image', image);
            fd.append("title", title);
            axios.put(APIs.portfolios + '/update/' + portfolio_id, fd).then(res => {
                if (res.data.status === "success") {
                    setEditOpen(false);
                    fetchPortfolios();
                    selectPortfolio({})
                }
            })
        }
        else {
            const fd = new FormData();
            fd.append('image', image);
            fd.append("title", title);
            axios.post(APIs.portfolios + '/create', fd).then(res => {
                if (res.data.status === "success") {
                    setEditOpen(false);
                    fetchPortfolios();
                    selectPortfolio({})
                }
            })

        }
    }


    return (
        <div className={classes.tableResponsive}>
            <Button color='primary' onClick={() => { setEditOpen(true) }} variant='contained'>
                Add New Portfolio
            </Button>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
                        <TableCell>
                            Sr.No
                        </TableCell>
                        <TableCell>
                            Title
                        </TableCell>
                        <TableCell>
                            Image
                        </TableCell>
                        <TableCell>
                            Action
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {portfolios.map((port, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                {index + 1}
                            </TableCell>
                            <TableCell>
                                {port.title}
                            </TableCell>
                            <TableCell>
                                <img src={baseUrl + port.image} width={100} alt="" />
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => {
                                    selectPortfolio(port);
                                    setEditOpen(true)
                                }} color="primary">
                                    <Edit />
                                </IconButton>
                                <IconButton onClick={() => {
                                    selectPortfolio(port);
                                    setOpen(true)
                                }} color='secondary'>
                                    <Delete />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => {
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Fade in={open}>
                    <div
                        style={{
                            background: "#fff",
                            width: "50%",
                            padding: "32px",
                        }}
                    >
                        <h2 id="transition-modal-title">Are you sure you want to delete this Portfolio?</h2>
                        <p id="transition-modal-description">
                            This action is not reversible
                        </p>

                        <Button variant="outlined" size="small" color="default" onClick={() => {
                            setOpen(false)
                        }}>Cancel</Button>
                        <Button variant="contained" size="small" color="secondary" onClick={() => { deletePortfolio() }} style={{ marginLeft: 12 }}>Delete</Button>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={editopen}
                onClose={() => {
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Fade in={editopen}>
                    <div
                        style={{
                            background: "#fff",
                            width: "50%",
                            padding: "32px",
                        }}
                    >
                        <h2 id="transition-modal-title">{selectedPortfolio._id ? "Edit" : "Add"} Portfolio</h2>
                        <form onSubmit={portfolioAddUpdate}>
                            <CustomInput
                                labelText="Title..."
                                id="title"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: 'string',
                                    value: title.length > 0 ? title : selectedPortfolio.title ? selectedPortfolio.title : "",
                                    onChange: (e) => {
                                        setTitle(e.target.value)
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Title className={classes.inputAdornmentIcon} />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <CustomInput
                                labelText="Portfolio..."
                                id="port"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: 'file',
                                    onChange: (e) => {
                                        setImage(e.target.files[0])
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Image className={classes.inputAdornmentIcon} />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <img src={image.name ? "" : selectedPortfolio._id ? baseUrl + selectedPortfolio.image : ""} width={100} />
                            <div>
                                <Button variant="outlined" size="small" color="default" onClick={() => {
                                    setEditOpen(false)
                                    selectPortfolio({})
                                    setTitle("")
                                }}>Cancel</Button>
                                <Button type="submit" variant="contained" size="small" color="secondary" onClick={() => { }} style={{ marginLeft: 12 }}>Submit</Button>
                            </div>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}

export default Portfolio