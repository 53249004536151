/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import { createBrowserHistory } from "history";
import ReactDOM from "react-dom";
import { Redirect, Route, Router, Switch } from "react-router-dom";

import { init } from "helper/auth";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import LoginPage from 'views/Pages/LoginPage';

// import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

const hist = createBrowserHistory();
ReactDOM.render(
  <Router history={hist}>
    <Switch>
      {/* <Route path="/rtl" component={RtlLayout} /> */}
      {init() ?
        <><Route path="/admin" component={AdminLayout} /> <Redirect from="/" to="/admin/banners" /></> :

        <>
          {/* <Route path="/auth" component={AuthLayout} /> */}
          <Route path="/auth/login-page" component={LoginPage} />
          <Redirect from="/" to="/auth/login-page" />
        </>
      }
    </Switch>
  </Router>,
  document.getElementById("root")
);
