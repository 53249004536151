import React from 'react'
import { Backdrop, Button, Fade, IconButton, InputAdornment, Modal, Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';
import { Delete, Edit, Image } from '@material-ui/icons';
import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { APIs, baseUrl } from 'variables/API';
import CustomInput from 'components/CustomInput/CustomInput';

const useStyles = makeStyles(styles);

function Banner() {
    const classes = useStyles();
    const [banners, setBanners] = useState([]);
    const [open, setOpen] = useState(false);
    const [editopen, setEditOpen] = useState(false);
    const [selectedBanner, selectBanner] = useState({});
    const [image, setImage] = useState({});
    useEffect(() => {
        fetchBanners();
    }, [])
    const fetchBanners = () => {
        axios.get(APIs.sliders + '?is_delete=no').then(res => {
            console.log(res.data);
            setBanners(res.data.data.docs)
        })
    }

    const deleteBanner = () => {
        const banner_id = selectedBanner._id;
        axios.delete(APIs.sliders + "/delete/" + banner_id).then(res => {
            // if (res.data.status === 'success') {
            setOpen(false);
            fetchBanners();
            selectBanner({});
            // }
        }).catch(err => {
            if (err) {
                console.log(err)
            }
        })
    }

    const bannerAddUpdate = (e) => {
        e.preventDefault();
        const banner_id = selectedBanner._id;
        if (banner_id) {
            const fd = new FormData();
            fd.append('bannerImage', image);
            fd.append("link", "");
            axios.put(APIs.sliders + '/update/' + banner_id, fd).then(res => {
                if (res.data.status === "success") {
                    setEditOpen(false);
                    fetchBanners();
                    selectBanner({})
                }
            })
        }
        else {
            const fd = new FormData();
            fd.append('bannerImage', image);
            fd.append("link", "");
            axios.post(APIs.sliders + '/create', fd).then(res => {
                if (res.data.status === "success") {
                    setEditOpen(false);
                    fetchBanners();
                    selectedBanner({})
                }
            })

        }
    }


    return (
        <div className={classes.tableResponsive}>
            <Button color='primary' onClick={() => { setEditOpen(true) }} variant='contained'>
                Add New Banner
            </Button>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
                        <TableCell>
                            Sr.No
                        </TableCell>
                        <TableCell>
                            Image
                        </TableCell>
                        <TableCell>
                            Action
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {banners.map((ban, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                {index + 1}
                            </TableCell>
                            <TableCell>
                                <img src={baseUrl + ban.bannerImage} width={100} alt="" />
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => {
                                    selectBanner(ban);
                                    setEditOpen(true)
                                }} color="primary">
                                    <Edit />
                                </IconButton>
                                <IconButton onClick={() => {
                                    selectBanner(ban);
                                    setOpen(true)
                                }} color='secondary'>
                                    <Delete />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => {
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Fade in={open}>
                    <div
                        style={{
                            background: "#fff",
                            width: "50%",
                            padding: "32px",
                        }}
                    >
                        <h2 id="transition-modal-title">Are you sure you want to delete this Banner?</h2>
                        <p id="transition-modal-description">
                            This action is not reversible
                        </p>

                        <Button variant="outlined" size="small" color="default" onClick={() => {
                            setOpen(false)
                        }}>Cancel</Button>
                        <Button variant="contained" size="small" color="secondary" onClick={() => { deleteBanner() }} style={{ marginLeft: 12 }}>Delete</Button>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={editopen}
                onClose={() => {
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Fade in={editopen}>
                    <div
                        style={{
                            background: "#fff",
                            width: "50%",
                            padding: "32px",
                        }}
                    >
                        <h2 id="transition-modal-title">{selectedBanner._id ? "Edit" : "Add"} Banner</h2>
                        <form onSubmit={bannerAddUpdate}>
                            <CustomInput
                                labelText="Banner..."
                                id="email"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: 'file',
                                    onChange: (e) => {
                                        setImage(e.target.files[0])
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Image className={classes.inputAdornmentIcon} />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <img src={image.name ? "" : selectedBanner._id ? baseUrl + selectedBanner.bannerImage : ""} width={100} />
                            <div>
                                <Button variant="outlined" size="small" color="default" onClick={() => {
                                    setEditOpen(false)
                                    selectBanner({})
                                }}>Cancel</Button>
                                <Button type="submit" variant="contained" size="small" color="secondary" onClick={() => { }} style={{ marginLeft: 12 }}>Submit</Button>
                            </div>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}

export default Banner